import React, { useEffect, useState } from 'react';
import 'react-toggle/style.css';
import { Spin, ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import enUS from 'antd/es/locale/en_US';
import Router from './Router';
import BackModalVersion from './components/extra/BackVersionModal';

function App() {
    const [locale, setLocale] = useState(frFR);
    useEffect(() => {
        if (localStorage.getItem('lng')) {
            switch (localStorage.getItem('lng')) {
                case 'en':
                    setLocale(enUS);
                    break;
                case 'fr':
                    setLocale(frFR);
                    break;

                default:
                    setLocale(frFR);
                    break;
            }
        }
    }, []);
    return (
        <ConfigProvider locale={locale}>
            <React.Suspense
                fallback={
                    <div className="w-full h-screen flex justify-center items-center">
                        <Spin spinning tip="Gantt Manager" />
                    </div>
                }
            >
                <Router />
                <BackModalVersion />
            </React.Suspense>
        </ConfigProvider>
    );
}

export default App;
